import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import ReactGA from "react-ga4";
import Video from "./Video";
import Upload from "./Upload";
import LoadingBar from "./LoadingBar";

const Dropzone = ({
  selectedVideo,
  setSelectedVideo,
  selectedPlatform,
  loading,
  setLoading,
  selectedFormat,
  setMediaType,
  mediaType,
  setShowPngs,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setLoading(true);
      const file = acceptedFiles[0];
      setShowPngs(false);
      if (file && file.type.startsWith("image/")) {
        console.log("image inserted");

        setMediaType("image");
        setTimeout(() => {
          setSelectedVideo(URL.createObjectURL(file));
          setLoading(false);
          ReactGA.event({
            category: "Dropzone",
            action: "image_inserted",
          });
        }, 500);
      } else if (file && file.type.startsWith("video/")) {
        console.log("video inserted");
        setMediaType("video");
        setTimeout(() => {
          setSelectedVideo(URL.createObjectURL(file));
          setLoading(false);
          ReactGA.event({
            category: "Dropzone",
            action: "video_inserted",
          });
        }, 500);
      } else {
        setLoading(false);
      }
    },
    [setSelectedVideo, setLoading, setMediaType]
  );

  const onDropRejected = (fileRejections) => {
    console.log("Abgelehnte Dateien:", fileRejections);

    if (fileRejections.length > 0) {
      alert("Please upload only one file.");
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: "video/*,image/*",
    multiple: false,
  });

  return (
    <div
      className={selectedVideo && !loading ? "dropzone has-video" : "dropzone"}
      {...getRootProps()}
    >
      <input
        {...getInputProps({
          onChange: (event) => {
            setSelectedVideo(URL.createObjectURL(event.target.files[0]));
          },
          multiple: false,
        })}
      />
      {loading ? (
        <LoadingBar />
      ) : selectedVideo ? (
        <Video
          selectedVideo={selectedVideo}
          selectedPlatform={selectedPlatform}
          selectedFormat={selectedFormat}
          mediaType={mediaType}
        />
      ) : (
        <Upload
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
        />
      )}
    </div>
  );
};

export default Dropzone;
