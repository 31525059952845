import React from "react";
import "../App.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="disclaimer">
        <p>
          Disclaimer: While we strive to ensure the accuracy and timeliness of
          our information, we cannot guarantee that it will always be 100%
          up-to-date with the latest UI changes of social platforms. No data is
          sent to any server; all processing occurs directly on your device.
        </p>
      </div>
      <div className="footer-links">
        <p>
          <a href="https://paia-intelligence.com/privacy" target="_blank">
            Privacy Policy
          </a>{" "}
          <a href="https://paia-intelligence.com/imprint" target="_blank">
            Imprint
          </a>{" "}
          <a href="https://paia-intelligence.com" target="_blank">
            © Safezone-Check 2024 by Paia Intelligence
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
