import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import Router components
import "./App.css";
import ReactGA from "react-ga4";
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";
import CookieBanner from "./components/CookieBanner";
import CookiePolicy from "./pages/CookiePolicy"; // Import the Cookie Policy page

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    if (cookiesAccepted) {
      ReactGA.initialize("G-403WWGJTDW"); // Initialize Google Analytics only if cookies are accepted
    }
  }, [cookiesAccepted]);

  useEffect(() => {
    const imagesToPreload = [
      "/images/TikTok_Safezone_organic.png",
      "/images/IGReels_Safezone.png",
      "/images/IGStory_Safezone.png",
      "/images/youTubeShorts_Safezone.png",
      "/images/logo.png",
    ];

    imagesToPreload.forEach((imagePath) => {
      const img = new Image();
      img.src = imagePath;
    });
  }, []);

  return (
    <Router>
      <CookieBanner setCookiesAccepted={setCookiesAccepted} />
      <Header />
      <Routes>
        <Route path="/" element={<Body />} /> {/* Main body of your app */}
        <Route path="/cookie-policy" element={<CookiePolicy />} />{" "}
        {/* Cookie policy page */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
