import React from "react";

const LoadingBar = () => {
  return (
    <>
      <p className="hint">Laden ...</p>
      <div className="loading-bar-container">
        <div
          className="loading-bar-filling"
          style={{ animation: "fillBar 0.5s forwards" }}
        ></div>
      </div>
    </>
  );
};

export default LoadingBar;
