import React from "react";
import "../App.css";

const Header = () => {
  return (
    <div className="header">
      <img src="/images/logo.png" alt="Logo" className="logo" />
      <button
        className="upload-button"
        onClick={() =>
          (window.location.href =
            "mailto:mail@safezone-check.com?subject=Feedback or Question about Safezone Check!")
        }
      >
        Contact
      </button>
    </div>
  );
};

export default Header;
