import React from "react";
import Settings from "./Settings";

const Information = ({
  setSelectedVideo,
  setSelectedPlatform,
  selectedVideo,
  selectedPlatform,
  selectedFormat,
  setSelectedFormat,
}) => {
  return (
    <div className="information">
      <h1 className="color-accent">Check your Content</h1>

      {selectedVideo ? (
        <Settings
          setSelectedVideo={setSelectedVideo}
          selectedPlatform={selectedPlatform}
          setSelectedPlatform={setSelectedPlatform}
          selectedFormat={selectedFormat}
          setSelectedFormat={setSelectedFormat}
        />
      ) : (
        <>
          <p>
            Use this tool to ensure important elements like your logo, product,
            supers, faces, and others are within the safezone to avoid the risk
            of elements being covered within certain inventory.
          </p>
          <ul className="list">
            <li>⏰ Save time and unnecessary feedback loops</li>
            <li>📱 On device processing</li>
            <li>🚀 100% automatic and free</li>
          </ul>
        </>
      )}
    </div>
  );
};

export default Information;
