import React, { useState } from "react";
import Information from "./Information";
import Dropzone from "./Dropzone";
import "../App.css";

const Body = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState("tiktok");
  const [selectedFormat, setSelectedFormat] = useState("forYou");
  const [loading, setLoading] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [showPngs, setShowPngs] = useState(true);

  return (
    <div>
      <div className={selectedVideo ? "body has-video" : "body"}>
        <Information
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          selectedPlatform={selectedPlatform}
          setSelectedPlatform={setSelectedPlatform}
          selectedFormat={selectedFormat}
          setSelectedFormat={setSelectedFormat}
        />
        <img
          src="/images/tiktok3DIcon.png"
          className={`tiktok-icon ${!showPngs ? "hide-icon" : ""}`}
          alt="Floating TikTok Icon"
        />
        <img
          src="/images/instagram3DIcon.png"
          className={`instagram-icon ${!showPngs ? "hide-icon" : ""}`}
          alt="Floating Instagram Icon"
        />
        <img
          src="/images/youtube3DIcon.png"
          className={`youtube-icon ${!showPngs ? "hide-icon" : ""}`}
          alt="Floating YouTube Icon"
        />
        <Dropzone
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          selectedPlatform={selectedPlatform}
          selectedFormat={selectedFormat}
          setSelectedFormat={setSelectedFormat}
          setLoading={setLoading}
          loading={loading}
          mediaType={mediaType}
          setMediaType={setMediaType}
          showPngs={showPngs}
          setShowPngs={setShowPngs}
        />
      </div>
    </div>
  );
};

export default Body;
