import React from "react";

const Video = ({
  selectedVideo,
  selectedPlatform,
  selectedFormat,
  mediaType,
}) => {
  return (
    <>
      {/* Display Safezone Overlay based on platform and format */}
      {selectedPlatform === "tiktok" && (
        <img
          src="/images/TikTok_Safezone_organic.png"
          alt="TikTok Organic Safezone Overlay"
          className="overlay"
        />
      )}
      {selectedPlatform === "instagram" && selectedFormat === "reels" && (
        <img
          src="/images/IGReels_Safezone.png"
          alt="Instagram Reels Safezone Overlay"
          className="overlay"
        />
      )}
      {selectedPlatform === "instagram" && selectedFormat === "storyIG" && (
        <img
          src="/images/IGStory_Safezone.png"
          alt="Instagram Story Safezone Overlay"
          className="overlay"
        />
      )}
      {selectedPlatform === "youtube" && selectedFormat === "shorts" && (
        <img
          src="/images/YouTubeShorts_Safezone.png"
          alt="YouTube Shorts Safezone Overlay"
          className="overlay"
        />
      )}

      {/* Display uploaded video or image */}
      {mediaType === "image" ? (
        <img
          src={selectedVideo}
          className="uploaded-image"
          alt="Uploaded Content"
        />
      ) : (
        <video
          src={selectedVideo}
          className="video"
          autoPlay
          loop
          muted
          playsInline
        />
      )}
    </>
  );
};

export default Video;
