import React from "react";
import "../App.css"; // Reuse global styles

const CookiePolicy = () => {
  return (
    <div className="policy-container">
      <h1>Cookie Policy</h1>
      <p>
        This Cookie Policy explains how our website uses cookies and other
        tracking technologies to provide a better user experience. It describes
        what cookies are, what types of cookies we use, why we use them, and how
        you can control or manage your cookie preferences.
      </p>

      <h2>1. What Are Cookies?</h2>
      <p>
        Cookies are small text files that are placed on your device (computer,
        smartphone, or other electronic devices) when you visit our website.
        They allow our website to recognize your device and store some
        information about your preferences or past actions.
      </p>

      <h2>2. Why Do We Use Cookies?</h2>
      <p>We use cookies for several reasons, including:</p>
      <ul>
        <li>To ensure that our website functions properly and optimally</li>
        <li>To remember your preferences and improve your user experience</li>
        <li>
          To gather analytics data, helping us improve our services and content
        </li>
        <li>To enable advertisements tailored to your interests</li>
      </ul>

      <h2>3. Types of Cookies We Use</h2>
      <h3>3.1. Essential Cookies</h3>
      <p>
        These cookies are necessary for the website to function properly.
        Without these cookies, some parts of our website would not work. They do
        not collect any personal data.
      </p>

      <h3>3.2. Analytics and Performance Cookies</h3>
      <p>
        These cookies help us understand how visitors interact with our website
        by collecting information anonymously. This helps us improve our
        website's functionality and user experience.
      </p>

      <h3>3.3. Advertising Cookies</h3>
      <p>
        Advertising cookies are used to deliver advertisements that are relevant
        to you. They track your browsing habits across different websites to
        show you ads based on your interests.
      </p>

      <h2>4. How You Can Manage Cookies</h2>
      <p>
        You can manage or disable cookies through your browser settings. Most
        browsers allow you to refuse or delete cookies. For more information on
        how to do this, visit your browser’s help page:
      </p>
      <ul>
        <li>
          Google Chrome:{" "}
          <a href="https://support.google.com/chrome/answer/95647?hl=en">
            Manage cookies in Chrome
          </a>
        </li>
        <li>
          Mozilla Firefox:{" "}
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
            Manage cookies in Firefox
          </a>
        </li>
        <li>
          Microsoft Edge:{" "}
          <a href="https://support.microsoft.com/en-us/help/4027947/windows-delete-cookies">
            Manage cookies in Edge
          </a>
        </li>
        <li>
          Safari:{" "}
          <a href="https://support.apple.com/en-us/HT201265">
            Manage cookies in Safari
          </a>
        </li>
      </ul>

      <h2>5. Changes to Our Cookie Policy</h2>
      <p>
        We may update this Cookie Policy from time to time to reflect changes in
        technology, legal requirements, and our business practices. We encourage
        you to review this page periodically for the latest information about
        our use of cookies.
      </p>

      <h2>6. Contact Us</h2>
      <p>
        If you have any questions about this Cookie Policy or how we handle
        cookies and other tracking technologies, feel free to contact us at{" "}
        <a href="mailto:support@yourwebsite.com">support@yourwebsite.com</a>.
      </p>
    </div>
  );
};

export default CookiePolicy;
