import React, { useState, useEffect } from "react";
import "../App.css"; // Use App.css for styles

const CookieBanner = ({ setCookiesAccepted }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isBannerDismissed, setIsBannerDismissed] = useState(false);

  useEffect(() => {
    const cookiesConsent = localStorage.getItem("cookiesConsent");
    if (!cookiesConsent) {
      setIsVisible(true); // Show the banner if no consent is stored
    } else {
      setCookiesAccepted(cookiesConsent === "true");
      setIsBannerDismissed(true);
    }
  }, [setCookiesAccepted]);

  const acceptCookies = () => {
    localStorage.setItem("cookiesConsent", "true");
    setCookiesAccepted(true);
    setIsVisible(false);
    setIsBannerDismissed(true); // Show the reopen button
  };

  const rejectCookies = () => {
    localStorage.setItem("cookiesConsent", "false");
    setCookiesAccepted(false);
    setIsVisible(false);
    setIsBannerDismissed(true); // Show the reopen button
  };

  const acceptEssentialCookies = () => {
    localStorage.setItem("cookiesConsent", "essential"); // Storing consent for essential cookies
    setCookiesAccepted(false); // Essential cookies only, no tracking
    setIsVisible(false);
    setIsBannerDismissed(true); // Show the reopen button
  };

  const reopenBanner = () => {
    setIsVisible(true);
    setIsBannerDismissed(false);
  };

  if (!isVisible && !isBannerDismissed) return null;

  return (
    <>
      {isVisible && (
        <div className="cookie-banner">
          <p>
            This website uses cookies to improve your experience. You can accept
            all cookies or only essential ones.{" "}
            <a href="/cookie-policy" className="cookie-link">
              Read more
            </a>
          </p>
          <button onClick={acceptCookies}>Accept All Cookies</button>
          <button onClick={acceptEssentialCookies}>
            Accept Essential Only
          </button>
          <button onClick={rejectCookies}>Reject All</button>
        </div>
      )}

      {isBannerDismissed && (
        <button className="reopen-button" onClick={reopenBanner}>
          🍪
        </button>
      )}
    </>
  );
};

export default CookieBanner;
