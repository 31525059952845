import React from "react";

const Settings = ({
  setSelectedVideo,
  setSelectedPlatform,
  selectedPlatform,
  setSelectedFormat,
  selectedFormat,
}) => {
  return (
    <div className="settings-container">
      <p className="setting-hint">
        Nicht alle Plattformen und ihre Formate haben die gleichen
        Sicherheitszonen. Bitte wähle die gewünschte Plattform und das Format
        aus:
      </p>
      {/* Plattform auswählen */}
      <div className="setting-category">
        <p>Plattform</p>
        <hr />
        <button
          className={`setting-button ${
            selectedPlatform === "instagram" ? "setting-button-selected" : ""
          }`}
          onClick={() => {
            setSelectedPlatform("instagram");
            setSelectedFormat("reels");
          }}
        >
          Instagram
        </button>
        <button
          className={`setting-button ${
            selectedPlatform === "tiktok" ? "setting-button-selected" : ""
          }`}
          onClick={() => {
            setSelectedPlatform("tiktok");
            setSelectedFormat("forYou");
          }}
        >
          TikTok
        </button>
        <button
          className={`setting-button ${
            selectedPlatform === "youtube" ? "setting-button-selected" : ""
          }`}
          onClick={() => {
            setSelectedPlatform("youtube");
            setSelectedFormat("shorts");
          }}
        >
          YouTube
        </button>
      </div>

      {/* Format auswählen */}
      <div className="setting-category">
        <p>Format</p>
        <hr />
        <div>
          {selectedPlatform === "instagram" ? (
            <>
              <button
                className={`setting-button ${
                  selectedFormat === "reels" ? "setting-button-selected" : ""
                }`}
                onClick={() => setSelectedFormat("reels")}
              >
                Reels
              </button>
              <button
                className={`setting-button ${
                  selectedFormat === "storyIG" ? "setting-button-selected" : ""
                }`}
                onClick={() => setSelectedFormat("storyIG")}
              >
                Story
              </button>
            </>
          ) : selectedPlatform === "tiktok" ? (
            <button
              className={`setting-button ${
                selectedFormat === "forYou" ? "setting-button-selected" : ""
              }`}
              onClick={() => setSelectedFormat("forYou")}
            >
              For You
            </button>
          ) : selectedPlatform === "youtube" ? (
            <button
              className={`setting-button ${
                selectedFormat === "shorts" ? "setting-button-selected" : ""
              }`}
              onClick={() => setSelectedFormat("shorts")}
            >
              Shorts
            </button>
          ) : null}
        </div>
      </div>

      <div className="setting-category">
        <p>Einstellungen</p>
        <hr />
        <button
          className="setting-button"
          onClick={() => setSelectedVideo(null)}
        >
          🔄 Neues Video oder Bild hochladen
        </button>
      </div>
    </div>
  );
};

export default Settings;
