import React from "react";

const Upload = ({
  selectedVideo,
  setSelectedVideo,
  getRootProps,
  getInputProps,
  isDragActive,
}) => {
  return (
    <div>
      {isDragActive ? (
        <p>Drop your file here ...</p>
      ) : (
        <>
          <h2>
            Drop video or <br />
            image here
          </h2>
          <button className="upload-button">or choose</button>
          <p className="hint">supported file formats:</p>
          <p className="hint">mp4, mov, webM, jpg, png, gif.</p>
        </>
      )}
    </div>
  );
};

export default Upload;
